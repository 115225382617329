import { Typography } from "@mui/material";
import React, { useState } from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import { updateJsonExample, updateResponseJsonExample } from "../../constants";
import CodeContainer from "../../components/CodeContainer";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import JsonContainer from "../../components/JsonContainer";
import {
  codeFont,
  listItems,
  pageStyles,
  pageContent,
  textHeader,
  body,
} from "../../styleConstants";
import VersionSwitch from "../../components/VersionSwitch";

const IndexPage = () => {
  const [index, setIndex] = useState(1);

  const handleIndexChange = (newIndex) => {
    setIndex(newIndex);
  };

  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={7} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <Typography variant="h4" style={textHeader}>
            Update Order
          </Typography>
          <VersionSwitch handleIndexChange={handleIndexChange} index={index} />
          <Typography variant="body1" style={body}>
            Update any editable fields of a particular order via the order{" "}
            <code style={codeFont}>id</code> field.
          </Typography>
          {index === 0 ? (
            <CodeContainer text="PUT https://us-central1-globhe-app.cloudfunctions.net/api/v1/orders/:id" />
          ) : (
            <CodeContainer text="PUT https://us-central1-globhe-app.cloudfunctions.net/api/v2/orders/:id" />
          )}
          <Typography variant="body1" style={body}>
            There is limitation on which field can be editable. The following
            fields cannot be edited: The fields that cannot be updated are:
          </Typography>
          <ul>
            <li style={listItems}>
              <code style={codeFont}>locationMap</code>
            </li>
            <li style={listItems}>
              <code style={codeFont}>mapTypes</code>
            </li>
          </ul>

          <Typography variant="body1" style={body}>
            The <code style={codeFont}>deliveryDeadline</code>
            can be updated to a later date only. It is not allowed to change the
            deadline to an earlier date.
          </Typography>
          <Typography variant="body1" style={body}>
            Note that once a quote has been accepted, you are not able to update
            the order. This is because a pilot is being contracted to capture
            your data and any changes to the order will change the scope and the
            price of this order.
          </Typography>
          <Typography variant="body1" style={body}>
            In case you want to update items in an order that are not editable,
            please cancel the order and place a new order with the updated info
          </Typography>

          <Typography variant="h5" style={textHeader}>
            Example Request
          </Typography>
          {index === 0 ? (
            <CodeContainer text="PUT https://us-central1-globhe-app.cloudfunctions.net/api/v1/orders/123456789" />
          ) : (
            <CodeContainer text="PUT https://us-central1-globhe-app.cloudfunctions.net/api/v2/orders/123456789" />
          )}

          <JsonContainer text={updateJsonExample} />

          <Typography variant="h5" style={textHeader}>
            Example Response
          </Typography>
          <JsonContainer text={updateResponseJsonExample} />
        </Container>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
